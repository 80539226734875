import { Subtitles, Title } from "@mui/icons-material";

const data = [
  {
    id: 1,
    isArticle: false,
    title: "显微智能亮相土耳其2024 ExpoMED Eurasia，展示国际领先的医疗解决方案",
    subtitle:
      "2024 ExpoMED Eurasia在这里揭开了神秘的面纱，不仅汇聚了来自世界各地的顶尖医疗\n设备制造商，还展示了前沿的医疗技术，将医疗行业的未来推向了新的高度。",
    topImg: "/md/1/top.png",
    markdown: "/md/1/1.md",
  },
  {
    id: 2,
    isArticle: false,
    topImg: "/md/2/top.png",
    title: "显微智能亮相2024阿拉伯医疗设备博览会",
    subtitle:
      "显微智能SHINEVIA诚邀您参加2024阿拉伯医疗设备博览会（Arab Health）。Arab Health 2024 将于2024年1月29日至2月1日在迪拜世贸展览中心举行",
    markdown: "/md//2/2.md",
  },
  {
    id: 3,
    isArticle: false,
    topImg: "/md/3/3_1.png",
    title: "显微智能助力耳鼻喉科专业基地骨干师资培训班圆满圆满结束",
    subtitle:
      "本次培训班的成功举办，彰显了中国医师协会在耳鼻喉科领域教育培训上的领先地位。期待医师们将所学应用于实践，为患者带来更加全面、高效的医疗保障！",
    markdown: "/md/3/3.md",
  },
  {
    id: 4,
    isArticle: false,
    topImg: "/md/4/4_1.png",
    title: "祝贺显微智能荣膺山东省科学技术进步二等奖",
    subtitle:
      "在科技与医学交汇的辉煌时刻，显微智能携手山东大学齐鲁医院杨其峰教授团队，共同书写了乳腺癌诊疗领域的新篇章",
    markdown: "/md/4/4.md",
  },
  {
    id: 21,
    isArticle: true,
    background: "/md/21/21_1.png",
    imgtitle: "/md/21/21_2.png",
    title: "显微智能助力甘肃省肿瘤医院甲状旁腺自体荧光科研论文成功发表",
    subtitle: "【期刊名称】临床医学研究与试验 2024年2月 第9卷 第4期",
    markdown: "/md/21/21.md",
  },
  {
    id: 22,
    isArticle: true,
    background: "/md/22/22_1.png",
    imgtitle: "/md/22/22_2.png",
    title: "显微智能乳腺癌根治科研论文成功发表",
    subtitle: "【期刊名称】中国实用外科杂志 2024年1月 第44卷 第1期",
    markdown: "/md/22/22.md",
  },
  {
    id: 23,
    isArticle: false,
    topImg: "/md/23/23_1.png",
    title: "荧光腹腔镜已广泛应用于各种胆道手术",
    subtitle:
      "近年来，荧光腹腔镜已广泛应用于各种胆道手术，通过静脉注射ICG可实现胆道荧光显影，对清晰辨识肝外胆道,更好地寻找组织间隙，明确胆管走行，减少LC术中胆道损伤、减少术后并发症，并提升手术安全性方面具有重要作用。",
    markdown: "/md/23/23.md",
  },
];

export default data;
